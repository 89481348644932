._row_qjvba_1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: var(--control-size);
}

._row_qjvba_1 > *:not(:last-child) {
  margin-right: var(--pad-amount);
}

._row_qjvba_1:hover ._colorSlider_qjvba_12::-moz-range-track {
  background: linear-gradient(to right, var(--start-color), var(--end-color));
}

._row_qjvba_1:hover ._colorSlider_qjvba_12::-webkit-slider-runnable-track {
  background: linear-gradient(to right, var(--start-color), var(--end-color));
}

._row_qjvba_1:hover ._hueSlider_qjvba_20::-moz-range-track {
  background: linear-gradient(
    to right,
    hsl(0, 100%, 50%),
    hsl(15, 100%, 50%),
    hsl(30, 100%, 50%),
    hsl(45, 100%, 50%),
    hsl(60, 100%, 50%),
    hsl(75, 100%, 50%),
    hsl(90, 100%, 50%),
    hsl(105, 100%, 50%),
    hsl(120, 100%, 50%),
    hsl(135, 100%, 50%),
    hsl(150, 100%, 50%),
    hsl(165, 100%, 50%),
    hsl(180, 100%, 50%),
    hsl(195, 100%, 50%),
    hsl(210, 100%, 50%),
    hsl(225, 100%, 50%),
    hsl(240, 100%, 50%),
    hsl(255, 100%, 50%),
    hsl(270, 100%, 50%),
    hsl(285, 100%, 50%),
    hsl(300, 100%, 50%),
    hsl(315, 100%, 50%),
    hsl(330, 100%, 50%),
    hsl(360, 100%, 50%)
  );
}

._row_qjvba_1:hover ._hueSlider_qjvba_20::-webkit-slider-runnable-track {
  background: linear-gradient(
    to right,
    hsl(0, 100%, 50%),
    hsl(15, 100%, 50%),
    hsl(30, 100%, 50%),
    hsl(45, 100%, 50%),
    hsl(60, 100%, 50%),
    hsl(75, 100%, 50%),
    hsl(90, 100%, 50%),
    hsl(105, 100%, 50%),
    hsl(120, 100%, 50%),
    hsl(135, 100%, 50%),
    hsl(150, 100%, 50%),
    hsl(165, 100%, 50%),
    hsl(180, 100%, 50%),
    hsl(195, 100%, 50%),
    hsl(210, 100%, 50%),
    hsl(225, 100%, 50%),
    hsl(240, 100%, 50%),
    hsl(255, 100%, 50%),
    hsl(270, 100%, 50%),
    hsl(285, 100%, 50%),
    hsl(300, 100%, 50%),
    hsl(315, 100%, 50%),
    hsl(330, 100%, 50%),
    hsl(360, 100%, 50%)
  );
}
