._widget_x5udz_1 {
  border: 1px solid var(--control-color);
}

._widget_x5udz_1:not(:first-child) {
  margin-top: var(--pad-amount);
}

._widget_x5udz_1 > summary {
  font-size: 1em;
  font-family: var(--font-family);
  line-height: var(--control-size);
  height: var(--control-size);
  padding: 0 var(--pad-amount);
  user-select: none;
  cursor: s-resize;
  font-weight: bold;

  background-color: var(--control-color);
  color: var(--chosen-color-solid);
}

._widget_x5udz_1[open] > summary {
  border-bottom: 1px dotted var(--control-color);
  cursor: n-resize;
}

._content_x5udz_28 {
  padding: var(--pad-amount);
}
