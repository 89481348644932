*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  --font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 14px;
  font-family: var(--font-family);
}

input {
  font-size: 1em;
  font-family: var(--font-family);
}

code {
  font-family: var(--font-family);
}

._app_ykmrf_29 {
  position: relative;

  --chosen-color: #fff;
  --control-color: #000;

  --line-size: 44px;
  --control-size: 40px;
  --pad-amount: 8px;

  padding-bottom: 64px;

  color: var(--control-color);
}

/* @media (min-width: 500px) {
  .app {
    --line-size: 34px;
    --control-size: 28px;
  }
} */

@media (min-width: 500px) {
  ._app_ykmrf_29 {
    --line-size: 28px;
    --control-size: 24px;
    /* --pad-amount: 4px; */
  }
}

._display_ykmrf_59 {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

._display_ykmrf_59 > div {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

._display_ykmrf_59 ._checkerboard_ykmrf_75 {
  background-image: linear-gradient(45deg, #808080 25%, transparent 25%),
    linear-gradient(-45deg, #808080 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #808080 75%),
    linear-gradient(-45deg, transparent 75%, #808080 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}

._ui_ykmrf_84 {
  padding: var(--pad-amount);
}

._logo_ykmrf_88 {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--pad-amount);
}

._input_ykmrf_96 {
  position: relative;
  margin-bottom: var(--pad-amount);
}

._widgets_ykmrf_101 {
  position: relative;
}

._randomize_ykmrf_105 {
  appearance: none;
  background-color: transparent;
  border: none;
  outline: none;
  width: var(--control-size);
  height: var(--control-size);
  cursor: pointer;
}

._die_ykmrf_115 {
  width: 100%;
  height: 100%;
  border: 1px solid var(--control-color);
}

._dieDot_ykmrf_121 {
  stroke: var(--control-color);
  stroke-width: 12;
}

@media (min-width: 500px) {
  ._ui_ykmrf_84 {
    max-width: 360px;
  }
}
