._row_8gj7e_1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: var(--control-size);
}

._row_8gj7e_1 > *:not(:last-child) {
  margin-right: var(--pad-amount);
}

._copy_8gj7e_12 ._row_8gj7e_1:not(:first-child) {
  margin-top: var(--pad-amount);
}

._copy_8gj7e_12 ._divider_8gj7e_16 {
  width: 100%;
  height: 0;
  border-top: 1px dotted var(--control-color);
  margin-top: var(--pad-amount);
}
