._row_1ftia_1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: var(--control-size);
}

._row_1ftia_1 > *:not(:last-child) {
  margin-right: var(--pad-amount);
}

._row_1ftia_1:hover ._colorSlider_1ftia_12::-moz-range-track {
  background: linear-gradient(to right, var(--start-color), var(--end-color));
}

._row_1ftia_1:hover ._colorSlider_1ftia_12::-webkit-slider-runnable-track {
  background: linear-gradient(to right, var(--start-color), var(--end-color));
}
