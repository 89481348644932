._button_1ocfh_1 {
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 1em;
  font-family: var(--font-family);
  font-weight: normal;
  color: var(--control-color);
  height: var(--control-size);
  line-height: var(--control-size);
  padding: 0 0.4em;
  border: 1px solid var(--control-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

._button_1ocfh_1:active {
  background-color: var(--control-color);
  color: var(--chosen-color);
}
