._input_153dn_1 {
  appearance: none;
  -webkit-appearance: none;

  position: relative;
  width: 100%;
  height: var(--control-height);
  background-color: transparent;
}

._input_153dn_1:focus {
  outline: none;
}

._input_153dn_1::-webkit-slider-runnable-track {
  height: 5px;
  border: 1px dotted var(--control-color);
  background-color: transparent;
}

._input_153dn_1::-webkit-slider-thumb {
  position: relative;
  appearance: none;
  background-color: transparent;
  height: calc(var(--control-size) - 5px);
  width: calc(var(--control-size) - 5px);
  border: 1px solid var(--control-color);
  transform: translateY(calc(-50% + 2px));
}

._input_153dn_1::-moz-range-track {
  height: 3px;
  border: 1px dotted var(--control-color);
  background-color: transparent;
}

._input_153dn_1::-moz-range-thumb {
  position: relative;
  appearance: none;
  background-color: transparent;
  height: calc(var(--control-size) - 7px);
  width: calc(var(--control-size) - 7px);
  border: 1px solid var(--control-color);
  border-radius: 0;
}
