._input_1lu30_1 {
  position: relative;
  appearance: none;
  --webkit-appearance: none;
  width: 100%;
  background-color: transparent;
  border: 1px solid var(--control-color);
  color: var(--control-color);
  font-size: 1em;
  font-family: var(--font-family);
  padding: var(--pad-amount);
  height: var(--control-size);
  color: var(--control-color);
  border-radius: 0;
}

._input_1lu30_1::placeholder {
  color: var(--control-color);
  opacity: 0.5;
}

._input_1lu30_1:focus {
  outline: 2px solid var(--control-color);
}

._input_1lu30_1::selection {
  color: var(--chosen-color-solid);
  background-color: var(--control-color);
}

._input_1lu30_1[aria-invalid="true"] {
  border: 2px solid red;
}

._input_1lu30_1[aria-invalid="true"]::after {
  content: "!";
  font-weight: bold;
  text-align: right;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--control-color);
}
