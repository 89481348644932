._toolbar_f6x5r_1 {
  border-bottom: 1px dotted var(--control-color);
  padding-bottom: var(--pad-amount);
  display: flex;
  align-items: center;
}

._toolbar_f6x5r_1 > *:not(:last-child) {
  margin-right: var(--pad-amount);
}

._list_f6x5r_12 {
  display: flex;
  flex-flow: column nowrap;
}

._list_f6x5r_12 > li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--pad-amount);
}

._itemControls_f6x5r_24 {
  display: flex;
  align-items: center;
}

._itemControls_f6x5r_24 > *:not(:last-child) {
  margin-right: var(--pad-amount);
}

._display_f6x5r_33 {
  display: flex;
  align-items: center;
}

._swatch_f6x5r_38 {
  width: var(--control-size);
  height: var(--control-size);
  border: 1px solid var(--control-color);
}

._text_f6x5r_44 {
  margin-left: var(--pad-amount);
}

._empty_f6x5r_48 {
  margin-top: var(--pad-amount);
}
