._logo_oe3z2_1 {
  display: flex;
  align-items: center;
  line-height: var(--control-size);
}

._icon_oe3z2_7 {
  border: none;
  outline: none;
  position: relative;
  width: var(--control-size);
  height: var(--control-size);
  margin-right: var(--pad-amount);
  background-color: var(--control-color);
  overflow: hidden;
}

._text_oe3z2_18 {
  display: flex;
  flex-flow: column nowrap;
  font-weight: bold;
  font-family: sans-serif;
  font-size: 1.4em;
}

@media (min-width: 500px) {
  ._text_oe3z2_18 {
    font-size: 1em;
  }
}
